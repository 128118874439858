import { MessageBusService } from '../services/message-bus/message-bus.service';
import { MessageType } from '../services/message-bus/message';
import { getFromInjector } from '../utils/injector.utils';

export function TriggerMessage(messageType: MessageType, payload?: any) {
  return function (target: any, propertyKey: string | Symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const messageBus: MessageBusService = getFromInjector(MessageBusService);

      messageBus.dispatch({ type: messageType, payload: payload });

      return await originalMethod.apply(this, args);
    };
  };
}

export function TriggerButtonMessage(what: string, where: string) {
  return TriggerMessage(MessageType.ButtonClick, { what: what, where: where });
}
