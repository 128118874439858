import { Injector, ProviderToken } from '@angular/core';

export function getFromInjector<T>(token: ProviderToken<T>): T {
  const injector = <Injector | undefined>(<any>window).injector;

  if (!injector) {
    throw new Error('Injector not found');
  }

  return injector.get(token);
}

export function setInjector(injector: Injector) {
  (<any>window).injector = injector;
}
